// src/App.js
import React, { useEffect, useState } from 'react';
import './App.css';

function Banner() {
    // State to track visibility of the box and image
    const [isVisible, setVisible] = useState(true);

    // Function to handle image click
    const handleImageClick = () => {
        setVisible(false);

        // Set a timeout to reappear after 3 seconds
        setTimeout(() => {
            setVisible(true);
        }, 3000); // 3000 milliseconds = 3 seconds
    };

    return (
        <div className="banner bg-cover bg-center text-center py-60 flex flex-col items-center justify-center">
            {isVisible && (
                <div className="border border-white p-8 rounded-lg shadow-lg inline-block">
                    <img
                        src="shake.gif" // Replace with your image URL
                        alt="VenomCraft Logo"
                        className="mx-auto mt-4 cursor-pointer" // Center the image and add margin-top
                        onClick={handleImageClick} // Handle click event
                    />
                </div>
            )}
        </div>
    );
}

function Card({ title, content, onClick }) {
    return (
        <div
            className="card flex flex-col items-center justify-center text-center shadow rounded-lg p-4 m-2 w-full max-w-xs cursor-pointer hover:bg-red-700"
            onClick={() => onClick(content)}
        >
            <h3 className="text-lg font-semibold mb-2 header-neon">{title}</h3>
            <p className="text-gray-300">{content || 'N/A'}</p>
        </div>
    );
}

function ServerInfo() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchServerData() {
            try {
                const response = await fetch('https://api.mcsrvstat.us/bedrock/3/venomcraft.cloud');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setData(data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }

        fetchServerData();
    }, []);

    if (loading) {
        return <div className="text-center mt-5 text-lg font-semibold text-white">Loading...</div>;
    }

    if (error) {
        return <div className="text-center mt-5 text-lg text-red-500">Error: {error.message}</div>;
    }

    const {
        hostname,
        port,
        motd = {},
        players = {},
        gamemode,
        software,
        version,
        protocol = {}
    } = data || {};

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    // Combine players online and max, handling 'N/A'
    const playersOnline = players.online === 'N/A' ? 0 : players.online;
    const playersMax = players.max;
    const playersContent = `${playersOnline}/${playersMax}`;

    return (
        <div className="w-full flex flex-col items-center">
            <br></br>
            <a href="minecraft://?addExternalServer=VenomCraft|venomcraft.cloud:19132">
                <img src="open_game_bttn.png" alt="Server Icon" className="server-image" />
            </a>
            <br></br>
            <br></br>
                <img src="/logo2.jpg" alt="Logo" className="w-80 h-80 mb-4 vibrate-on-hover pop-out-on-click" />
            <br></br>
                <img src="server_info_img.png" alt="Server Icon" className="server-image" />
            <br></br>
            <div className="flex flex-wrap justify-center card-container">
                <Card title="Hostname" content={hostname} onClick={copyToClipboard} />
                <Card title="Port" content={port} onClick={copyToClipboard} />
                <Card title="MOTD" content={motd.clean} onClick={copyToClipboard} />
                <Card title="Players" content={playersContent} onClick={copyToClipboard} />
                <Card title="Gamemode" content={gamemode} onClick={copyToClipboard} />
                <Card title="Software" content={software} onClick={copyToClipboard} />
                <Card title="Version" content={version} onClick={copyToClipboard} />
                <Card title="Protocol Version" content={protocol.version} onClick={copyToClipboard} />
            </div>

            {/* Add the YouTube videos here */}
            <div className="flex flex-col items-center mt-4">
                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/uMKAJFOiZN0" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen
                    className="mb-4"
                ></iframe>
                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/WjhXW4KIfi0" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
}


function Footer() {
    return (
        <footer className="bg-gray-900 text-white py-4 mt-8">
            <div className="container mx-auto flex flex-col items-center justify-center space-y-4">
                <div className="flex space-x-4">
                    <a
                        href="https://x.com/venema2010"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-neon-green"
                    >
                        <img src="x.png" alt="Twitter" className="w-8 h-8" />
                    </a>
                    <a
                        href="https://discord.gg/ZWmMNh6yTK"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-neon-green"
                    >
                        <img src="d.png" alt="Discord" className="w-8 h-8" />
                    </a>
                    <a
                        href="https://github.com/mikenike360"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-neon-green"
                    >
                        <img src="g.png" alt="GitHub" className="w-8 h-8" />
                    </a>
                    <a
                        href="https://www.youtube.com/@Venomcraftofficial"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-neon-green"
                    >
                        <img src="y.png" alt="YouTube" className="w-8 h-8" />
                    </a>
                </div>
                <div className="mt-4">
                    <a
                        href="https://yourwebsite.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-400 hover:text-neon-green"
                    >
                        © 2024 VenomCraft Labs
                    </a>
                </div>
            </div>
        </footer>
    );
}

function App() {
    return (
        <div className="min-h-screen bg-black">
            <Banner />
            <div className="container mx-auto p-6">
                <ServerInfo />
            </div>
            <Footer />
        </div>
    );
}

export default App;
